// Journey
export var JourneyType;
(function (JourneyType) {
    JourneyType[JourneyType["mock"] = -1] = "mock";
    JourneyType[JourneyType["unknown"] = 0] = "unknown";
    JourneyType[JourneyType["scheduled"] = 1] = "scheduled";
    JourneyType[JourneyType["event"] = 2] = "event";
})(JourneyType || (JourneyType = {}));
export var JourneyUiFeatureFlag = {
    emailLinkTrackingV2: "emailLinkTrackingV2",
    clientUserAgentDetails: "clientUserAgentDetails",
    journeyEntryDropoffAnalyticsEnabled: "JourneyEntryDropoffAnalyticsEnabled",
    midTileDropoffAnalyticsEnabled: "JourneyMidTileDropoffAnalyticsEnabled"
};
export var JourneyState;
(function (JourneyState) {
    JourneyState[JourneyState["Draft"] = 0] = "Draft";
    JourneyState[JourneyState["Live"] = 1] = "Live";
    JourneyState[JourneyState["Stopped"] = 2] = "Stopped";
    JourneyState[JourneyState["Publishing"] = 3] = "Publishing";
    JourneyState[JourneyState["Deleted"] = 4] = "Deleted";
    JourneyState[JourneyState["Completing"] = 5] = "Completing";
    JourneyState[JourneyState["Completed"] = 6] = "Completed";
    JourneyState[JourneyState["LiveEditing"] = 7] = "LiveEditing";
    JourneyState[JourneyState["Stopping"] = 8] = "Stopping";
})(JourneyState || (JourneyState = {}));
export var FrequencyCapType;
(function (FrequencyCapType) {
    FrequencyCapType[FrequencyCapType["ApplyFCBySkippingMessages"] = 0] = "ApplyFCBySkippingMessages";
    FrequencyCapType[FrequencyCapType["IgnoreFC"] = 1] = "IgnoreFC";
})(FrequencyCapType || (FrequencyCapType = {}));
export var JourneyPurpose;
(function (JourneyPurpose) {
    JourneyPurpose[JourneyPurpose["Marketing"] = 721460000] = "Marketing";
    JourneyPurpose[JourneyPurpose["DoubleOptIn"] = 721460001] = "DoubleOptIn";
})(JourneyPurpose || (JourneyPurpose = {}));
export var DOIComplianceStatus;
(function (DOIComplianceStatus) {
    DOIComplianceStatus[DOIComplianceStatus["Enabled"] = 534120001] = "Enabled";
    DOIComplianceStatus[DOIComplianceStatus["Disabled"] = 534120000] = "Disabled";
})(DOIComplianceStatus || (DOIComplianceStatus = {}));
export var QuietTimeSettingsOptions;
(function (QuietTimeSettingsOptions) {
    QuietTimeSettingsOptions[QuietTimeSettingsOptions["ApplyDefaultQuietTime"] = 0] = "ApplyDefaultQuietTime";
    QuietTimeSettingsOptions[QuietTimeSettingsOptions["ApplyOtherQuietTime"] = 1] = "ApplyOtherQuietTime";
    QuietTimeSettingsOptions[QuietTimeSettingsOptions["IgnoreQuietTime"] = 2] = "IgnoreQuietTime";
})(QuietTimeSettingsOptions || (QuietTimeSettingsOptions = {}));
export var FrequencyType;
(function (FrequencyType) {
    FrequencyType[FrequencyType["year"] = 0] = "year";
    FrequencyType[FrequencyType["month"] = 1] = "month";
    FrequencyType[FrequencyType["week"] = 2] = "week";
    FrequencyType[FrequencyType["day"] = 3] = "day";
    FrequencyType[FrequencyType["hour"] = 4] = "hour";
    FrequencyType[FrequencyType["minute"] = 5] = "minute";
})(FrequencyType || (FrequencyType = {}));
export var TimeUnit;
(function (TimeUnit) {
    TimeUnit[TimeUnit["second"] = 0] = "second";
    TimeUnit[TimeUnit["minute"] = 1] = "minute";
    TimeUnit[TimeUnit["hour"] = 2] = "hour";
    TimeUnit[TimeUnit["day"] = 3] = "day";
    TimeUnit[TimeUnit["week"] = 4] = "week";
    TimeUnit[TimeUnit["month"] = 5] = "month";
})(TimeUnit || (TimeUnit = {}));
export var ChannelType;
(function (ChannelType) {
    ChannelType["email"] = "Email";
    ChannelType["sms"] = "Sms";
    ChannelType["push"] = "Push";
})(ChannelType || (ChannelType = {}));
export var EventType;
(function (EventType) {
    EventType[EventType["external"] = 0] = "external";
    EventType[EventType["computed"] = 1] = "computed";
    EventType[EventType["interaction"] = 2] = "interaction";
    EventType[EventType["cdsEntityDataOperation"] = 3] = "cdsEntityDataOperation";
})(EventType || (EventType = {}));
export var WaitForType;
(function (WaitForType) {
    WaitForType[WaitForType["event"] = 0] = "event";
    WaitForType[WaitForType["segment"] = 1] = "segment";
})(WaitForType || (WaitForType = {}));
// Trigger Types
export var TriggerTypes;
(function (TriggerTypes) {
    TriggerTypes["recurring"] = "Recurring";
    TriggerTypes["ongoing"] = "Ongoing";
    TriggerTypes["oneTime"] = "OneTime";
    TriggerTypes["event"] = "Event";
})(TriggerTypes || (TriggerTypes = {}));
// Action Types
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["condition"] = "Condition";
    ActionTypes["conditionMultiBranch"] = "ConditionV2";
    ActionTypes["email"] = "Email";
    ActionTypes["experimentation"] = "Experimentation";
    ActionTypes["journeyOptimization"] = "JourneyOptimization";
    ActionTypes["push"] = "Push";
    ActionTypes["sms"] = "Sms";
    ActionTypes["switch"] = "Switch";
    ActionTypes["event"] = "Event";
    ActionTypes["wait"] = "Delay";
    ActionTypes["seriesWait"] = "SeriesDelay";
    ActionTypes["ifThenAfterBranch"] = "IfThenAfterBranch";
    ActionTypes["eventTrigger"] = "EventTrigger";
    ActionTypes["series"] = "Series";
    ActionTypes["channelDefinition"] = "ChannelDefinition";
    ActionTypes["createRecord"] = "CreateRecord";
    ActionTypes["split"] = "Split";
})(ActionTypes || (ActionTypes = {}));
// Condition Types
export var ConditionTypes;
(function (ConditionTypes) {
    ConditionTypes["unaryOperator"] = "UnaryOperator";
    ConditionTypes["binaryOperator"] = "BinaryOperator";
    ConditionTypes["grouping"] = "Grouping";
})(ConditionTypes || (ConditionTypes = {}));
export var BranchingType;
(function (BranchingType) {
    BranchingType[BranchingType["JourneyOptimization"] = 0] = "JourneyOptimization";
    BranchingType[BranchingType["Experimentation"] = 1] = "Experimentation";
})(BranchingType || (BranchingType = {}));
export var allowPeople;
(function (allowPeople) {
    allowPeople["allowed"] = "Allowed";
    allowPeople["notAllowed"] = "NotAllowed";
})(allowPeople || (allowPeople = {}));
export var ActivityParameterKeys;
(function (ActivityParameterKeys) {
    ActivityParameterKeys["Subject"] = "subject";
    ActivityParameterKeys["Owner"] = "owner";
    ActivityParameterKeys["DefaultOwner"] = "defaultOwner";
    ActivityParameterKeys["DueDateAfter"] = "scheduledend";
    ActivityParameterKeys["Note"] = "description";
    ActivityParameterKeys["DisplayName"] = "topic";
    ActivityParameterKeys["CurrencyId"] = "transactionCurrencyId";
    ActivityParameterKeys["IsMarketingQualified"] = "isMarketingQualified";
    ActivityParameterKeys["MatchingStrategy"] = "matchingStrategy";
    ActivityParameterKeys["PredecessorSalesActionId"] = "predecessorSalesActionId";
})(ActivityParameterKeys || (ActivityParameterKeys = {}));
export var WaitType;
(function (WaitType) {
    WaitType["wait"] = "Wait";
    WaitType["waitUntil"] = "WaitUntil";
    WaitType["waitForAttribute"] = "WaitForAttribute";
})(WaitType || (WaitType = {}));
export var OffsetType;
(function (OffsetType) {
    OffsetType["before"] = "Before";
    OffsetType["after"] = "After";
    OffsetType["current"] = "Current";
})(OffsetType || (OffsetType = {}));
export var HoldoutExperimentationControlGroup;
(function (HoldoutExperimentationControlGroup) {
    HoldoutExperimentationControlGroup["noHoldout"] = "noHoldout";
    HoldoutExperimentationControlGroup["holdout"] = "holdout";
})(HoldoutExperimentationControlGroup || (HoldoutExperimentationControlGroup = {}));
export var SERIES_SINGLE_EXTERNAL_EVENT_KEY = "event";
export var SeriesExitTypeKeys;
(function (SeriesExitTypeKeys) {
    SeriesExitTypeKeys["ConditionMet"] = "ConditionMet";
    SeriesExitTypeKeys["TimeLimit"] = "TimeLimit";
})(SeriesExitTypeKeys || (SeriesExitTypeKeys = {}));
export var ActionDependency;
(function (ActionDependency) {
    ActionDependency["StandAlone"] = "StandAlone";
    ActionDependency["SingleAction"] = "SingleAction";
    ActionDependency["ChoiceOfActions"] = "ChoiceOfActions";
})(ActionDependency || (ActionDependency = {}));
export var KeywordTriggerType;
(function (KeywordTriggerType) {
    KeywordTriggerType["EXACT_REPLY"] = "Exact";
    KeywordTriggerType["BEGINNING_OF_REPLY"] = "StartsWith";
    KeywordTriggerType["ANYWHERE_IN_REPLY"] = "Anywhere";
})(KeywordTriggerType || (KeywordTriggerType = {}));
export var ExperimentationWinningMetric;
(function (ExperimentationWinningMetric) {
    ExperimentationWinningMetric["ClickThroughRate"] = "ClickThroughRate";
    ExperimentationWinningMetric["OpenRate"] = "OpenRate";
    ExperimentationWinningMetric["Goal"] = "Goal";
})(ExperimentationWinningMetric || (ExperimentationWinningMetric = {}));
export var ExperimentationVersion;
(function (ExperimentationVersion) {
    ExperimentationVersion["VersionA"] = "VersionA";
    ExperimentationVersion["VersionB"] = "VersionB";
})(ExperimentationVersion || (ExperimentationVersion = {}));
export var MessageDesignation;
(function (MessageDesignation) {
    MessageDesignation[MessageDesignation["Commercial"] = 534120000] = "Commercial";
    MessageDesignation[MessageDesignation["Transactional"] = 534120001] = "Transactional";
})(MessageDesignation || (MessageDesignation = {}));
export var ValidationMessageType;
(function (ValidationMessageType) {
    ValidationMessageType["error"] = "error";
    ValidationMessageType["errorOnPublish"] = "errorOnPublish";
    ValidationMessageType["warning"] = "warning";
    ValidationMessageType["missingValue"] = "missingValue";
})(ValidationMessageType || (ValidationMessageType = {}));
export var ConditionOperandType;
(function (ConditionOperandType) {
    ConditionOperandType[ConditionOperandType["unknown"] = 0] = "unknown";
    ConditionOperandType[ConditionOperandType["number"] = 1] = "number";
    ConditionOperandType[ConditionOperandType["string"] = 2] = "string";
    ConditionOperandType[ConditionOperandType["boolean"] = 3] = "boolean";
    ConditionOperandType[ConditionOperandType["datetime"] = 4] = "datetime";
    ConditionOperandType[ConditionOperandType["picklist"] = 5] = "picklist";
    ConditionOperandType[ConditionOperandType["multiselectpicklist"] = 6] = "multiselectpicklist";
    ConditionOperandType[ConditionOperandType["lookup"] = 7] = "lookup";
})(ConditionOperandType || (ConditionOperandType = {}));
export var BinaryOperator;
(function (BinaryOperator) {
    BinaryOperator[BinaryOperator["unknown"] = 0] = "unknown";
    BinaryOperator[BinaryOperator["contains"] = 1] = "contains";
    BinaryOperator[BinaryOperator["equals"] = 2] = "equals";
    BinaryOperator[BinaryOperator["notEquals"] = 3] = "notEquals";
    BinaryOperator[BinaryOperator["endsWith"] = 4] = "endsWith";
    BinaryOperator[BinaryOperator["notEndsWith"] = 5] = "notEndsWith";
    BinaryOperator[BinaryOperator["beginsWith"] = 6] = "beginsWith";
    BinaryOperator[BinaryOperator["notBeginsWith"] = 7] = "notBeginsWith";
    BinaryOperator[BinaryOperator["notContains"] = 8] = "notContains";
    BinaryOperator[BinaryOperator["lessThan"] = 9] = "lessThan";
    BinaryOperator[BinaryOperator["lessThanEqualsTo"] = 10] = "lessThanEqualsTo";
    BinaryOperator[BinaryOperator["greaterThan"] = 11] = "greaterThan";
    BinaryOperator[BinaryOperator["greaterThanEqualsTo"] = 12] = "greaterThanEqualsTo";
    BinaryOperator[BinaryOperator["containsAny"] = 13] = "containsAny";
    BinaryOperator[BinaryOperator["notContainsAny"] = 14] = "notContainsAny";
    BinaryOperator[BinaryOperator["containsAll"] = 15] = "containsAll";
    BinaryOperator[BinaryOperator["notContainsAll"] = 16] = "notContainsAll";
    BinaryOperator[BinaryOperator["isBefore"] = 17] = "isBefore";
    BinaryOperator[BinaryOperator["isOnOrBefore"] = 18] = "isOnOrBefore";
    BinaryOperator[BinaryOperator["isAfter"] = 19] = "isAfter";
    BinaryOperator[BinaryOperator["isOnOrAfter"] = 20] = "isOnOrAfter";
    BinaryOperator[BinaryOperator["isBetween"] = 21] = "isBetween";
})(BinaryOperator || (BinaryOperator = {}));
export var UnaryOperator;
(function (UnaryOperator) {
    UnaryOperator[UnaryOperator["unknown"] = 0] = "unknown";
    UnaryOperator[UnaryOperator["isSegmentMember"] = 1] = "isSegmentMember";
    UnaryOperator[UnaryOperator["isNotSegmentMember"] = 2] = "isNotSegmentMember";
    UnaryOperator[UnaryOperator["isEmpty"] = 3] = "isEmpty";
    UnaryOperator[UnaryOperator["isNotEmpty"] = 4] = "isNotEmpty";
})(UnaryOperator || (UnaryOperator = {}));
export var LogicalOperator;
(function (LogicalOperator) {
    LogicalOperator[LogicalOperator["unknown"] = 0] = "unknown";
    LogicalOperator[LogicalOperator["and"] = 1] = "and";
    LogicalOperator[LogicalOperator["or"] = 2] = "or";
})(LogicalOperator || (LogicalOperator = {}));
// Parameters
export var ParameterTypes;
(function (ParameterTypes) {
    ParameterTypes["static"] = "Static";
    ParameterTypes["eventDataSource"] = "EventDataSource";
    ParameterTypes["legalDataSource"] = "LegalDataSource";
    ParameterTypes["cdsDataSource"] = "CdsDataSource";
    ParameterTypes["customerVoiceAnonymousSurvey"] = "CustomerVoiceAnonymousSurvey";
    ParameterTypes["customerVoiceNonAnonymousSurvey"] = "CustomerVoiceNonAnonymousSurvey";
    ParameterTypes["range"] = "Range";
    ParameterTypes["dateTimeRelative"] = "DateTimeRelative";
    ParameterTypes["dateTimePartial"] = "DateTimePartial";
    ParameterTypes["placeholder"] = "Placeholder";
})(ParameterTypes || (ParameterTypes = {}));
export var DateTimeUnit;
(function (DateTimeUnit) {
    DateTimeUnit[DateTimeUnit["year"] = 1] = "year";
    DateTimeUnit[DateTimeUnit["month"] = 2] = "month";
    DateTimeUnit[DateTimeUnit["day"] = 3] = "day";
})(DateTimeUnit || (DateTimeUnit = {}));
export var SplitType;
(function (SplitType) {
    SplitType["orderedAbsolute"] = "OrderedAbsolute";
    SplitType["randomPercentage"] = "RandomPercentage";
})(SplitType || (SplitType = {}));
// Project
export var GoalCategory;
(function (GoalCategory) {
    GoalCategory[GoalCategory["OOB"] = 0] = "OOB";
    GoalCategory[GoalCategory["Custom"] = 1] = "Custom";
})(GoalCategory || (GoalCategory = {}));
export var GoalMeasurement;
(function (GoalMeasurement) {
    GoalMeasurement[GoalMeasurement["Percentage"] = 0] = "Percentage";
    GoalMeasurement[GoalMeasurement["AbsoluteNumber"] = 1] = "AbsoluteNumber";
})(GoalMeasurement || (GoalMeasurement = {}));
export var GoalEventType;
(function (GoalEventType) {
    GoalEventType[GoalEventType["Custom"] = 0] = "Custom";
    GoalEventType[GoalEventType["Interaction"] = 1] = "Interaction";
})(GoalEventType || (GoalEventType = {}));
